import React from 'react';
import {getUserToken,getUserID} from '../store/utility';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const position = [43.6, 3.88]

const myIcon = L.icon({
    iconUrl: 'images/leaf.png',
    iconSize: [20, 20],
    iconAnchor: [20, 20],
});

class MorphoMap extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
		staff : false,
        token : "",
        connected_user_id : -1,
        markers : undefined,
        popups : [],
	}

    componentDidMount(){
        this.state.connected_user_id =  getUserID();
        this.setState({token : getUserToken()});
    }

    componentDidUpdate(prevProps,prevState){
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({staff:res.data['staff']});
            });
        }

        if (JSON.stringify(this.props.markers) !== JSON.stringify(prevProps.markers) && this.props.markers !== undefined){
            this.state.popups = [];
            for (var item in this.props.markers.filter(item2 => item2 !== undefined && item2.longitude !== undefined && item2.latitude !==undefined)){
                if (this.props.markers !== undefined && this.props.markers[item] !== undefined){
                    const u_ip = this.props.markers[item].IP;
                    if (this.state.popups !== undefined && this.state.popups[u_ip] === undefined && this.props.markers[item].latitude !== undefined && this.props.markers[item].longitude !== undefined){
                this.state.popups[u_ip] = <Marker icon={myIcon} position={[this.props.markers[item].latitude,this.props.markers[item].longitude]}><Popup>{this.props.markers.map(d => <div>{d !== undefined && d.longitude === this.props.markers[item].longitude && d.latitude === this.props.markers[item].latitude? <span>{d.id_user !== undefined?"User ="+d.id_user:"Anonymous"} IP = {d.IP} Connections count = {d.count}</span>:<span></span>}</div>)}</Popup></Marker>
               }}
            }
            this.setState({markers:this.props.markers.filter(item => item !== undefined && item.longitude !== undefined && item.latitude !== undefined)});
        }
    }

    render(){
    return(
			<div>
                <MapContainer style={{margin:'auto',height: '800px', width: '1200px'}} center={position} zoom={2}>
                    <TileLayer
                    
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                    {Object.values(this.state.popups)}
                </MapContainer>
			</div>
        );
    }
}

export default MorphoMap;