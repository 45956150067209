import {connect} from "react-redux";
import React from "react";
import DatasetInstance from "./datasetInstanceContainer";
import {sort_functions} from "../DataLoader";
import {ReturnEmptyIfUndefined} from "../utils/tools";
import {getUserID} from "../store/utility";
import SortingPanel from "./SortingPanel";


function ApplyTagFiltering(data, tag_list, tags_by_dataset) {
    if (tag_list.length < 1) {
        return data;
    }

    return data.filter(set => {
        if (!(set.id in tags_by_dataset) || tags_by_dataset[set.id].length < 1) {
            return false;
        }
        let flag = false;

        tags_by_dataset[set.id].map((tag) => {
            if (tag_list.includes(tag)) {
                flag = true;
            }
        });
        return flag;
    });
}

function ApplyAdvancedFiltering(data, fav,user,other,favid) {
    if (!fav && !user && !other) {
        return data;
    }

    return data.filter(set => {
        let flagFav = false;
        let flagUser = false;
        let flagOthers = false;
        if (fav){
            flagFav = favid.some(idset =>
                idset === set.id.toString()
            )
        }

        if (user){
            flagUser = set.id_people.toString() === getUserID();
        }

        if (other){
            flagOthers = set.id_people.toString() !== getUserID();
        }
        return flagFav || flagUser || flagOthers;
    });
}

function ApplyGroupFiltering(data, group_list, sharings, filteredStudiesList,public_filter,orphan_filter) {
    if ((group_list === undefined || group_list.length < 1) && (filteredStudiesList === undefined || filteredStudiesList.length < 1) && !public_filter && !orphan_filter) {
        return data;
    }
    return data.filter(set => {
        let flag = false;
        let flagOrphanGroup = false;
        let flagPublic = false;
        let flagStudy = false;
        let flagOrphan = false;
        const group_sharings = sharings.filter(share => share.base === "dataset" && share.id_base === set.id && share.id_group !== null)
        if (group_list !== undefined && group_list.length > 0) {
            if (group_sharings.length < 1) {
                flag = false;
            } else {
                flag = group_sharings.some(function (group) {
                    return group_list.includes(group.id_group);
                });
            }
        }

        if (public_filter){
            flagPublic = sharings.some(share => share.base === "dataset" && share.id_base === set.id && share.id_who === 0)
        }

        if (orphan_filter){
            flagOrphanGroup = group_sharings.length === 0;
        }

        if (filteredStudiesList !== undefined && filteredStudiesList.length > 0) {
            const orphan_groups = filteredStudiesList.filter(stud => stud + "".includes("orphan")).map(stud => parseInt(stud + "".replace("_orphans", "")))
            if (set.id_study_id !== null && set.id_study_id !== undefined) {
                flagStudy = filteredStudiesList.includes(set.id_study_id);
            }  {

                flagOrphan = group_sharings.some(function (group) {
                    return orphan_groups.includes(group.id_group);
                });
            }
        }
        return flagStudy || flag || flagOrphan || flagPublic || flagOrphanGroup;
    });
}

function ApplyTreeFiltering(data, tree_branch) {
    if (tree_branch === -1) {
        return data;
    }

    return data.filter(set => set.id_ncbi === tree_branch);


}

function ApplySearchFiltering(data, name, group, tag, allgroups, alltags, sharings, tags_by_set, filteredStudiesList, studyfilter, allStudyList) {
    let temp_data = data;
    let all_study = [];
    Object.keys(allStudyList).map(group_id => {
        allStudyList[group_id].map(final_study => {
            if (!all_study.includes(allStudyList[group_id])) {
                all_study.push(final_study);
            }
        });
    });

    if (name !== "" && name !== undefined) {
        temp_data = temp_data.filter(set => set.name.toLowerCase().includes(name.toLowerCase()))
    }


    let fstudy = undefined;
    if (studyfilter !== "" && studyfilter !== undefined) {
        fstudy = [];
        const studylist = all_study.filter(studt => studt.name.toLowerCase().includes(studyfilter.toLowerCase()));
        studylist.map(stud => {
            fstudy.push(stud.id);
        })
    }
    let fgroups = undefined;
    if (group !== "" && group !== undefined) {
        fgroups = [];
        const group_list = allgroups.filter(groupt => groupt.name.toLowerCase().includes(group.toLowerCase()));
        group_list.map(group => {
            fgroups.push(group.id);
        })

    }

    if ((group !== "" && group !== undefined) || (studyfilter !== "" && studyfilter !== undefined)) {
        temp_data = ApplyGroupFiltering(temp_data, fgroups, sharings, fstudy);
    }

    if (tag !== "" && tag !== undefined) {
        let ftags = [];
        const tag_list = alltags.filter(tagt => tagt.name.toLowerCase().includes(tag.toLowerCase()));
        tag_list.map(tag => {
            ftags.push(tag.id);
        })
        temp_data = ApplyTagFiltering(temp_data, ftags, tags_by_set);
    }


    return temp_data;


}

function ApplyFiltering(data, tag_list, group_list, current_tree_branch, dataset_by_group, tags_by_dataset, name, group, tag, allgroups, alltags, filteredStudiesList, studyfilter, allStudyList,public_filter,orphan_filtered,fav,user,others,favid) {
    data = ApplyGroupFiltering(data, group_list, dataset_by_group, filteredStudiesList,public_filter,orphan_filtered)
    data = ApplyTreeFiltering(data, current_tree_branch)
    data = ApplyTagFiltering(data, tag_list, tags_by_dataset)
    data = ApplyAdvancedFiltering(data, fav,user,others,favid)
    data = ApplySearchFiltering(data, name, group, tag, allgroups, alltags, dataset_by_group, tags_by_dataset, filteredStudiesList, studyfilter, allStudyList)
    return data;
}

class DatasetListView extends React.Component {
    state = {}


    render() {
        const current_sort = this.props.datasets.current_sort_type;
        const namefilter = this.props.datasets.current_filter_search_name;
        const tagfilter = this.props.datasets.current_filter_search_tag;
        const favid = Object.keys(this.props.user.favourite_by_dataset_id);
        const groupfilter = this.props.datasets.current_filter_search_group;
        const studyfilter = this.props.datasets.current_filter_search_study;
        const sortFunction = sort_functions[current_sort];
        const filteredTagList = ReturnEmptyIfUndefined(this.props.datasets.current_filtered_tags);
        const allTagList = ReturnEmptyIfUndefined(Object.values(this.props.tags.entities));
        const allGroupList = ReturnEmptyIfUndefined(Object.values(this.props.user.group_list));
        const allStudyList = ReturnEmptyIfUndefined(Object.values(this.props.user.studies_by_group));
        const filteredGroupList = this.props.datasets.current_filtered_groups;
        const filteredStudiesList = this.props.datasets.current_filtered_studies;
        const filteredTreeBranch = this.props.datasets.current_filtered_tree_branch;
        const filtered_favourites = this.props.datasets.current_only_favourites;
        const filtered_user= this.props.datasets.current_only_user;
        const filtered_others= this.props.datasets.current_only_others;
        const tags_by_dataset = this.props.tags.dataset_by_tag;
        const sharings = Object.values(this.props.sharings);
        const filtereddatasets = ApplyFiltering(Object.values(this.props.datasets.entities), filteredTagList, filteredGroupList, filteredTreeBranch, sharings, tags_by_dataset, namefilter, groupfilter, tagfilter, allGroupList, allTagList, filteredStudiesList, studyfilter, allStudyList,this.props.public_filtered,this.props.orphan_filtered,filtered_favourites,filtered_user,filtered_others,favid);
        const datasets = filtereddatasets.sort(sortFunction);

        return <div>

            {datasets.length > 0 && datasets.map(item => <div key={"dataset_"+item.id} style={{width: "100%"}}><DatasetInstance {...this.props} key={item.id}
                                                                                                                                set={item}></DatasetInstance><br/>
            </div>)
            }
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset,
        user: state.user,
        tags: state.tag,
        user_is_admin: state.user.is_staff,
        public_filtered : state.dataset.current_filtered_publics,
        orphan_filtered : state.dataset.current_filtered_orphans,
        sharings: state.sharing.entities,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(DatasetListView);