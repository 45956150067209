import * as actionTypes from './actionTypes';
import axios from 'axios';
import React from 'react';
import {LoadUserData} from "../../utils/api_callbacks";
import {getUserToken,getUserID,setCookie,removeCookie} from '../utility';
import {SetErrorMessage} from "../reducers/userSlice";

//change state to auth has started, then will check if success or fail in other functions
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

//return success when auth failed
export const authSuccess = token => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
}

//return error when auth failed
export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

//remove connection from localStorage
export const logout = () => {
    const u_token = getUserToken();
    const u_id = getUserID();
    removeCookie('token');
    removeCookie('user');
    removeCookie('right');
    axios.get('/api/storelogout/', {params : {user_id:u_id?u_id:0},headers: { Authorization: `Token ${u_token}` } }).then(res => {}).catch(err => {});
    return  {
        type: actionTypes.AUTH_LOGOUT
    }
}

//check a timer to logout when token expires
export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        },expirationTime)
    }
}

export const debugLogin = (userid,token) => {
    return dispatch => {

        axios.get('/api/getusertoken/', {
            params : { user_id: userid},
            headers: {  Authorization: `Token ${token}` }
        })
        .then( res => {
            const token = res.data;
            const user_id = userid;
            let is_staff = false;
            let is_beta = false;
            // console.log()
            axios.get('/api/storelogin/', {params :{user_id:user_id},headers: { Authorization: `Token ${token}` } }).then(res => {}).catch(err => {});
            //axios.get('/api/storelogin/', {headers: { Authorization: `Token ${token}` } });

            axios.get('/api/people-betatests/', { headers: { Authorization: `Token ${token}` } })
            .then(res => {
                is_beta=res.data['beta']
            }).catch(err => {})

            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${token}` } })
            .then(res => {
                is_staff=res.data['staff']
               // console.log("is_staff : "+is_staff);
                //this data is sensitive, encrypt it
                //localStorage.setItem('is_staff',encryptString(is_staff.toString())); 
                //setCookie('right',is_staff.toString());
                //browserHistory.push("/dataset");
                window.location.href = "dataset";
               // window.location.reload();
            }).catch(err => { window.location.href = "dataset";})
            //expir date = 8h 
            const expirationDate = new Date().setTime(new Date().getTime()+ 8 * 3600 * 1000);
            //localStorage.setItem('token',encryptString(token));
            setCookie('token',token);
         
            setCookie('user',user_id.toString());
               LoadUserData();
            //localStorage.setItem("expirationDate",expirationDate);          
            //localStorage.setItem('user_id',encryptString(user_id.toString()));
            dispatch(authSuccess(token));
            dispatch(checkAuthTimeout(1000 * (3600 * 3)));
        })
        .catch(err => {
        dispatch(authFail(err))
        })
    }
}

//when you click login, request, than store token during 1hour
export const authLogin = (username, password) => {
    return dispatch => {

        axios.post('/rest-auth/login/', {
            username: username,
            password: password
        })
        .then( res => {
            const token = res.data.key;
            let user_id = -1;
            let is_staff = false;
            let is_beta = false;
            dispatch(SetErrorMessage(""));
            // console.log()
            axios.get('/api/storelogin/', {params :{user_id:user_id},headers: { Authorization: `Token ${token}` } }).then(res => {}).catch(err => {});
            //axios.get('/api/storelogin/', {headers: { Authorization: `Token ${token}` } });
            axios({
                method: 'post',
                url: '/api/retrieveuserid/',
                headers: {Authorization: `Token ${token}`}
            }).then(res => {
                user_id = res.data['id'];
                if (user_id !== -1 && user_id !== undefined && user_id !== null) {
                    axios.get('/api/people-betatests/', {headers: {Authorization: `Token ${token}`}})
                        .then(res => {
                            is_beta = res.data['beta']
                        }).catch(err => {
                    })

                    axios.get('/api/people-admin/', {headers: {Authorization: `Token ${token}`}})
                        .then(res => {
                            is_staff = res.data['staff']
                            // console.log("is_staff : "+is_staff);
                            //this data is sensitive, encrypt it
                            //localStorage.setItem('is_staff',encryptString(is_staff.toString())); 
                            //setCookie('right',is_staff.toString());
                            //browserHistory.push("/dataset");
                            window.location.href = "dataset";
                            // window.location.reload();
                        }).catch(err => {
                        window.location.href = "dataset";
                    })
                    //expir date = 8h 
                    const expirationDate = new Date().setTime(new Date().getTime() + 8 * 3600 * 1000);
                    //localStorage.setItem('token',encryptString(token));
                    setCookie('token', token);
                    setCookie('user', user_id.toString());
                    //localStorage.setItem("expirationDate",expirationDate);          
                    //localStorage.setItem('user_id',encryptString(user_id.toString()));
                    dispatch(authSuccess(token));
                    dispatch(checkAuthTimeout(1000 * (3600 * 3)));
                }
            }).catch(err => {});
        })
        .catch(err => {
            dispatch(SetErrorMessage("Unable to connect this user : check username or password"));
            dispatch(authFail(err))
        })
    }
}

//when you signup, send request to django, than login
export const authSignup = (username, email, password,name,surname,phone,adress,fonction,quota,institution_text,laboratory_text,team_text) => {
    return dispatch => {
        dispatch(authStart());

        const the_token = localStorage.getItem('token');
        dispatch(authStart());
        axios({
            method: 'post',
            url: '/api/register/',
            data: {
                name:name,
                surname:surname,
                login: username,
                email: email,
                password: password,
                date:new Date(),
                exist: 1,
                id_right: 3,
                tel:phone,
                adress:adress,
                fonction:fonction,
                name_institution:institution_text,
                name_laboratory:laboratory_text,
                name_team:team_text,
                id_institution:0,
                id_laboratory:0,
                id_team:0,
                quota:quota,
                active_status:false
            }
          })
        .catch(err => {
        dispatch(authFail(err))
        })
    }
}
//function called when we need to check state, if token expiration date is reached, logout, else, start a timer for logout
export const authCheckState = () =>{
}