import React from 'react'
import {ReactTree} from '@naisutech/react-tree'
import {getUserToken} from '../store/utility';
import axios from 'axios';

class FileTreeView extends React.Component {
    constructor(props){
        super(props);
    }

    state = {
        token : "",
        source_folder : "",
        tree_created : false,
        update_view : false,
        tree_file : [
          ],
        
    }

    componentDidMount(){
      this.state.token = getUserToken();
      this.setState({source_folder:this.props.sourceFolder});
    }

    componentDidUpdate(prevProps,prevState){
      if (this.props.sourceFolder !== prevProps.sourceFolder){
        this.setState({source_folder:this.props.sourceFolder});
        this.setState({tree_created:false});
        axios.get('/api/filetree/',{params:{folder:this.props.sourceFolder}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            const result = [];
            result.push(res.data) ;
            this.setState({tree_file:result});
        });
      }

      if (this.state.tree_file  !== prevState.tree_file){
        this.setState({tree_created:true});
      }

      if (prevProps.updateView  !== this.props.updateView){
        this.setState({tree_created:false});
        axios.get('/api/filetree/',{params:{folder:this.props.sourceFolder}, headers: { Authorization: `Token ${this.state.token}` } })
              .then(res => {
                  const result = [];
                  result.push(res.data) ;
                  this.setState({tree_file:result});
              });
      }

    }

    handleNodeSelect= node =>{
      if (node.items === null && node.directory !== true){
        if (window.confirm(`Voulez vous supprimer le fichier \"${node.label}\"`)) { 
          axios.get('/api/rmvfile/',{params:{folder:node.id}, headers: { Authorization: `Token ${this.state.token}` } }).then(
            res => {
              this.setState({tree_created:false});
              axios.get('/api/filetree/',{params:{folder:this.props.sourceFolder}, headers: { Authorization: `Token ${this.state.token}` } })
              .then(res => {
                  const result = [];
                  result.push(res.data) ;
                  this.setState({tree_file:result});
              });
            }
          );
      }
        
      }
    }

    render() {

        return (
            <div>
                {
                  this.state.tree_created && 
                  <ReactTree
                      nodes={this.state.tree_file} // see data format
                      onSelect={this.handleNodeSelect} // fired every click of node or leaf with selected item as argument
                      darkMode={true} // true (default), false
                      size={"full"} // full (default), half, narrow
                  />
                }

            </div>
        );
    }

}

export default FileTreeView;