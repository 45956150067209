import React from "react";
import {ChooseGroup, PostNewStudy} from "../utils/api_callbacks";
import {ChooseSetFromUrl} from "./updateGroup";
import {connect} from "react-redux";
import {Button, Input} from "antd";
import {GetSetInUrl} from "../utils/tools";
import { useParams } from "react-router";
class CreateStudy extends React.Component {


    state = {
        new_study_name: "",
        new_study_description: "",

    }

    PostStudy = () => {
        if (this.props.user.current_choosen_group === -1) {
            return;
        }
        if (this.state.new_study_name === "") {
            return;
        }

        PostNewStudy(this.props.user.current_choosen_group, this.state.new_study_name, this.state.new_study_description)
    }

    componentDidMount() {
        let groupId = parseInt(this.props.match.params.id);
        ChooseGroup(groupId);

    }


    render() {

        const groups = Object.values(this.props.user.group_list).filter(group => group.id === this.props.user.current_choosen_group);
        if (groups.length < 1) {
            return <div></div>
        }
        const group = groups[0];
        return <div style={{textAlign:"center"}}>
            <h1 style={{textAlignLast:"center",color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',fontWeight:"bold",minWidth:'750px'}}>Update group : {group.name}</h1>
            <h3 style={{marginTop:'7%'}}>Create a new study</h3>
            <Input
                style={{width: '10%'}}
                placeholder={"Study name"}
                value={this.state.new_study_name}
                onChange={e => {
                    this.setState({new_study_name: e.target.value})
                }}>
            </Input>
            <br></br>
            <br></br>

            <Input
                style={{width: '10%'}}
                placeholder={"Study description"}
                value={this.state.new_study_description}
                onChange={e => {
                    this.setState({new_study_description: e.target.value})
                }}>
            </Input>
            <br></br>
            <br></br>
            <Button type="primary" onClick={this.PostStudy}
                    style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px'}}>
                Create study
            </Button>

        </div>
    }
}
    const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset,
        user_contacts: state.user.user_contacts,
        user: state.user,
        tags: state.tag,
        user_is_admin: state.user.is_staff,
        sharings: state.sharing.entities,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
    const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateStudy);

