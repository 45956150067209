import React from 'react';

class ScrollToTop extends React.Component {

    //component always in the website
    //auto scroll to top when change page
    componentDidUpdate(prevProps) {
      if (this.props.location  !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
  
    render() {
      return <div></div>
    }
  }
  
  export default ScrollToTop;
  
  