import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import * as actions from '../store/actions/auth';
import Icon from "@ant-design/icons";
import { Form } from '@ant-design/compatible';
import {
    Input,
    Button,
    Typography
  } from 'antd';
  

  const { Title } = Typography;

  //Loaded on create groupe page
  class ForgottenPasswordForm extends React.Component {
    //component data (R/W permissions)
    state = {
        email_sent_feedback: ""
    };

    //commit the group creation to django, than add creator as manager
    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if ( !err) {
          //connected user token (auth check)
            axios.get('/api/forgotpassword',{params:{'email':values.email}}).then(this.setState({email_sent_feedback: "Email has been sent, make sure to check the spam box if you don't receive the mail."}));
        }
    });
}
  
    componentDidMount() {
      
  }

    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <div >
        <Title style={{marginLeft:'43%'}}> Forgotten Password ? </Title>
        <Form style={{marginTop:'7%',marginLeft:'31%'}} onSubmit={this.handleSubmit}>
        <Form.Item>
            E-mail : 
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
            })(<Input autoComplete="new-password" style={{width:'300px',marginLeft: '10px'}} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)'}} />}
            placeholder="Email"/>)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" hidden={this.state.email_sent_feedback !== ""}htmlType="submit" style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}} >
                Send email
            </Button>
        </Form.Item>
        </Form>
        <br></br>
        {this.state.email_sent_feedback !== undefined?<span>{this.state.email_sent_feedback}</span>:<span></span>}
        </div>
      );
    }
  }
  
  const WrappedForgottenPasswordForm = Form.create({ name: 'forgotpw' })(ForgottenPasswordForm);

  //get the data from application
  const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error : state.error,
        isAuthenticated: state.token !== null,
    }
}

//bind onAuth function to global auth function
const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team) => dispatch(actions.authSignup(username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedForgottenPasswordForm);