import React from 'react';
import axios from 'axios';
import Icon from "@ant-design/icons";
import { Form } from '@ant-design/compatible';
import { List,
    Input,
    Button,
    Typography,
    Radio,
    AutoComplete,
    Checkbox,
    message  } from 'antd';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {encryptString,decryptString,getUserToken,getUserID} from '../store/utility'
import * as actions from '../store/actions/auth';
const { Title } = Typography;
const { Search } = Input;
  

const { Option } = AutoComplete;

class GroupDetail extends React.Component {
    constructor(props){
        super(props);

        //allow the function to access this
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    token = {};
    staff = false;


    state = {
        group_id: -1,
        group: {},
        owner: {},
        group_users: [],  
        private : true,
        connected_user_id : -1,
        token : "",
        staff : false,
        //delete confirmation window
        displayDeleteWindow: false,
        //list for names display
        institution : [],
        laboratory : [],
        team : [],
        status_user: [],
        all_users: [],
        //people by group tables
        people_groups: [],
        //list of contacts for an user
        user_contact: [],
        mail_message : "",
        update_message :"",
        user_inside_group : false,
        user : {},
        invited_email : ""
    }
 
    //onChange privacy for the group
    handlePrivacyChange = e => {
        this.setState({
            privacy: e.target.checked,
        });
      }
  
      //display delete window
      handledelete = e => {
        this.setState({displayDeleteWindow : true,});
      }

    //ask django to delete the group permanently
      deleteGroup = e =>{
            e.preventDefault();
            this.props.sendTracking(-1,"Web,Group,delete");
            axios({
                method: 'delete',
                url: `/api/group/${this.state.group.id}/`,
                headers :{ Authorization: `Token ${this.state.token}` }
              });
              this.props.history.push('/');
      }

      //ask django to create group relation, then send confirm mail
      sendEmailInvit = e =>{
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err && this.state.invited_email !== "") {
            axios({
                method: 'post',
                url: '/api/people-group-create/',
                data: {
                    id_group:this.state.group.id,
                    id_people:0,
                    date : new Date(),
                    status:"waiting",
                    email:this.state.invited_email
                },
                headers :{ Authorization: `Token ${this.state.token}` }
              }).then(res => this.setState({mail_message:"Group invitation has been sent if user exists !"})).catch(err => this.setState({mail_message:"Group invitation has been sent if user exists !"}));
          }
        });
      }


      hideDeletePanel = e =>{
            e.preventDefault();
            this.setState({displayDeleteWindow:false});
      }

      //get the status in group for a user id, to change the radio boxes
      getGroupStatus(id){
          let status = undefined;
          this.state.people_groups.map((item) =>  {
                if (item.id_people === id){
                    status = item.status.trim();
                    
                }
            });
            if (status === "member"){
                return 'member';}
            if (status === "manager"){
                return 'manager';
            }
            else
                return 'Deleted';
      }

      isAdmin(id){
          const status = this.getGroupStatus(id);
          return (status.replace(/\s/g, '') === 'manager');
    }

      //ask django to change user state in the group
      ChangeUserState (id,e) {
          let status = false;
        switch(e.target.value){
            case "Deleted":
                //console.log("User id : " + id + " is now deleted");

                //check if the relation exist, update it, else add it
                this.state.people_groups.map((peoplegroup) =>{
                    if (peoplegroup.id_people === id && peoplegroup.id_group === this.state.group.id){
                        axios({
                          method: 'put',
                          url: `/api/putpeoplegroup/${peoplegroup.id}/`,
                          data: {
                            id_people : peoplegroup.id_people,
                            id_group : peoplegroup.id_group,
                            date: peoplegroup.date,
                            status: "waiting",
                          },
                          headers :{ Authorization: `Token ${this.state.token}` }
                        }).then(res => {
                        if (this.state.group.id !== undefined){
                            axios.get('/api/people-group/',{ params:{'groupid':this.state.group.id},headers: { Authorization: `Token ${this.state.token}` } })
                            .then(res => {
                                this.setState({
                                    people_groups: res.data
                                });
                            })
                        }});
                        status = true;
                    }
                });
                break;
            case "member":
                //check if the relation exist, update it, else add it
                this.state.people_groups.map((peoplegroup) =>{
                    if (peoplegroup.id_people === id && peoplegroup.id_group === this.state.group.id){
                        axios({
                          method: 'put',
                          url: `/api/putpeoplegroup/${peoplegroup.id}/`,
                          data: {
                            id_people : peoplegroup.id_people,
                            id_group : peoplegroup.id_group,
                            date: peoplegroup.date,
                            status: "member",
                          },
                          headers :{ Authorization: `Token ${this.state.token}` }
                        }).then(res => {
                        if (this.state.group.id !== undefined){
                            axios.get('/api/people-group/',{ params:{'groupid':this.state.group.id},headers: { Authorization: `Token ${this.state.token}` } })
                            .then(res => {
                                this.setState({
                                    people_groups: res.data
                                });
                            })
                        }});
                        status = true;
                    }
                });
                if (!status){
                    axios({
                        method: 'post',
                        url: `/api/peoplegroup/`,
                        data: {
                          id_people : id,
                          id_group : this.state.group.id,
                          date: new Date(),
                          status: "member",
                        },
                        headers :{ Authorization: `Token ${this.state.token}` }
                      }).then(res => {
                      if (this.state.group.id !== undefined){
                          axios.get('/api/people-group/',{ params:{'groupid':this.state.group.id},headers: { Authorization: `Token ${this.state.token}` } })
                          .then(res => {
                              this.setState({
                                  people_groups: res.data
                              });
                          })
                      }});
                }
                break;
            case "manager":
                //check if the relation exist, update it, else add it
                this.state.people_groups.map((peoplegroup) =>{
                    if (peoplegroup.id_people === id && peoplegroup.id_group === this.state.group.id){
                        axios({
                          method: 'put',
                          url: `/api/putpeoplegroup/${peoplegroup.id}/`,
                          data: {
                            id_people : peoplegroup.id_people,
                            id_group : peoplegroup.id_group,
                            date: peoplegroup.date,
                            status: "manager",
                          },
                          headers :{ Authorization: `Token ${this.state.token}` }
                        }).then( res => {
                        if (this.state.group.id !== undefined){
                            axios.get('/api/people-group/',{ params:{'groupid':this.state.group.id},headers: { Authorization: `Token ${this.state.token}` } })
                            .then(res => {
                                this.setState({
                                    people_groups: res.data
                                });
                            })}
                        });
                        status = true;
                    }
                });
                if (!status){
                    axios({
                        method: 'post',
                        url: `/api/peoplegroup/`,
                        data: {
                          id_people : id,
                          id_group : this.state.group.id,
                          date: new Date(),
                          status: "manager",
                        },
                        headers :{ Authorization: `Token ${this.state.token}` }
                      }).then(res => {
                      if (this.state.group.id !== undefined){
                          axios.get('/api/people-group/',{ params:{'groupid':this.state.group.id},headers: { Authorization: `Token ${this.state.token}` } })
                          .then(res => {
                              this.setState({
                                  people_groups: res.data
                              });
                          })}
                      });
                }
                break;
        }

        message.info('User status updated !');
      }


      //test if an user is inside group, returning string instead of bool
      userInsideGroup(userTested){
          let test = "false";
          this.state.group_users.map((user) => {
          if (user.id === userTested.id)
          {
              test =  "true";
          }
        });
        return test;
    }
    
        //ask django for group modif
      handleSubmit = e => {
        this.props.sendTracking(-1,"Web,Group,update");

        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
          if (this.state.localStorage !== -1 && !err) {
            axios({
              method: 'put',
              url: `/api/group/${this.state.group.id}/`,
              data: {
                name : values.name,
                privacy: this.state.privacy?"private":"public",
                date : this.state.group.date,
                status: this.state.group.status,
                id_owner:this.state.group.id_owner
              },
              headers :{ Authorization: `Token ${this.state.token}` }
            }).then(res => this.setState({update_message:"Group has been updated !"})).catch(err => this.setState({update_message:"Error during update !"}));
            }
        });
      };

    isUserGroupReader = () => {
        return (!this.state.staff  && this.state.connected_user_id !== this.state.group.id_owner && this.getGroupStatus(this.state.connected_user_id) !== "manager");
    }
    componentDidMount() {
        //timer for display
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
        //get connected user token, than ask for url parameter ID person
        this.setState({connected_user_id: getUserID()});
        this.setState({token:getUserToken()});
        
        
        //ask for group info by adress bar
        if (localStorage.getItem('groupProps') !== undefined && this.props.location.aboutProps === undefined){
            this.props.location.aboutProps = JSON.parse(decryptString(localStorage.getItem('groupProps')));
          }
          //get user through webpage
        this.state.group_id = this.props.location.aboutProps;
        
            let savedGroup =  JSON.stringify(this.props.location.aboutProps)
            localStorage.setItem('groupProps', encryptString(savedGroup));

    }

    //delete timer when leaving page
    componentWillUnmount() {
        clearInterval(this.interval);
        let savedGroup =  JSON.stringify(this.props.location.aboutProps)
        try { 
            localStorage.setItem('groupProps', encryptString(savedGroup));
        }
        catch(e){
                if (e.name === "NS_ERROR_FILE_CORRUPTED"){
                    window.alert("Your browser's cache seems to be corrupted, try clearing the browser cache and try again");
                }
            }

    }
    
    handleConfirmBlur = e => {
          const { value } = e.target;
          this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
  
    componentDidUpdate(prevProps, prevState){   
        //if group users changed
        //ask django for lists
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({staff:res.data['staff']});
            });
        }
        
        if (prevState.connected_user_id !== this.state.connected_user_id && this.state.connected_user_id !== -1)
        {
            axios.get(`/api/user/${this.state.connected_user_id}`,{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    user: res.data
                });
            });
            //list of user contacts
            axios.get(`/api/usercontacts/`,{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    user_contact: res.data
                });
            });
                    
        axios.get(`/api/group/${this.state.group_id}/`,{headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                group: res.data
            })
        }).catch(err => {
            axios.get(`/api/publicgroup/${this.state.group_id}/`,{headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    group: res.data
                })
            });            
        });

    //get list of users
    axios.get('/api/user',{ headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
        this.setState({
            all_users: res.data
        });
    });
        }

        if (this.state.group_users !== prevState.group_users){
                axios.get(`/api/institution/`,{ headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        institution: res.data
                    });
                })
                axios.get(`/api/laboratory/`,{ headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                    laboratory: res.data
                });
            })
                axios.get(`/api/team/`,{ headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                    team: res.data
                });
            })
    }

        //user details
        if (this.state.group !== prevState.group){
            if (this.state.group.id_owner !== undefined){
                axios.get(`/api/people/${this.state.group.id_owner}/`,{ headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        owner: res.data
                    });
                })
            }
            //now we have the group, change the privacy of the detail
            this.setState({privacy:this.state.group.privacy==="private"})
                axios.get('/api/peoplebygroup/',{ params:{'groupid':this.state.group.id},headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        group_users: res.data
                    });
                });
            if (this.state.group.id !== undefined){
                axios.get('/api/people-group/',{ params:{'groupid':this.state.group.id},headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        people_groups: res.data
                    });
                })
            }
        }
    }

    //called everytime the DOM has changed
    //display it in card grid
    render() {
            const { getFieldDecorator } = this.props.form;
      return (
        this.state.token === null || this.state.token === "" ?
        <div>Please register to access this page</div>
        :
        <div className="globaldiv">
                    <Title style={{marginLeft:'43%'}}> Group update </Title>
        <div style={{width:'25%'}} className="floatingleft">

        <Form style={{marginTop:'20%'}} onSubmit={this.handleSubmit}>
        <Form.Item>
          Name :
          {getFieldDecorator('name', {
            initialValue:this.state.group.name,rules: [{ required: true, message: 'Please input a group!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Group name"
              disabled={this.isUserGroupReader()}
            />,
          )}
        </Form.Item>

        <Form.Item>
            <Checkbox disabled={this.isUserGroupReader()} checked={this.state.privacy} onChange={this.handlePrivacyChange}>{this.state.privacy?<span>Private</span>:<span>Public</span>}
           </Checkbox>
           
        </Form.Item>

          <Form.Item>
            <Button disabled={this.isUserGroupReader()} type="primary" htmlType="submit" style={{borderColor:'#3bc5fc',backgroundColor:'#3bc5fc',marginRight: '10px'}} >
                Update group
            </Button>

            <Button type="primary" disabled={this.isUserGroupReader()} onClick={(event) =>  this.setState({displayDeleteWindow : true,})} style={{borderColor:'#3bc5fc',backgroundColor:'#3bc5fc',marginRight: '10px'}} >
                Delete group
            </Button>
        </Form.Item>
        </Form>
        <div>{this.state.update_message !== undefined && this.state.update_message !== "" ? <span>{this.state.update_message}</span>:<span></span>}</div>
            {
                this.state.displayDeleteWindow?           
                <div>
                <h1>Are you sure to delete this group ?</h1>
                <Button type="danger" disabled={this.isUserGroupReader()} onClick={this.deleteGroup}  style={{borderColor:'#3bc5fc',backgroundColor:'#3bc5fc',marginRight: '10px'}}>Yes</Button>
                <Button type="primary" disabled={this.isUserGroupReader()} onClick={this.hideDeletePanel} style={{marginRight: '10px'}}>No</Button>
                </div>
                :
                <div></div>
            }
            </div>
            <br></br>
            <div style={{width:'74%'}} className="floatingright">
            <h2>Group Users</h2>
            <Search placeholder="Enter user name here..." id='username_search' />
            <br></br>        <br></br>
            {
                this.state.staff?
                <List
                itemLayout="horizontal"
                dataSource={this.state.all_users.filter(user => user['name'].includes(document.getElementById('username_search').value) || 
                user['login'].toString().includes(document.getElementById('username_search').value))}
                renderItem={item => (
                    <List.Item  extra = {
                    <Radio.Group onChange={(e) => this.ChangeUserState(item.id,e)} value={this.getGroupStatus(item.id)}>
                    <Radio value={"manager"}>Manager</Radio>
                    <Radio value={"member"}>Member</Radio>
                    <Radio value={"Deleted"}>Not in group</Radio>
                    </Radio.Group>}>
                    <List.Item.Meta
                    title={<NavLink exact to={{pathname:`/display-user/`,aboutProps:item}}>            {
                        item.name || item.surname?
                        <span style={item.id === this.state.group.id_owner?{color:'#44D4FF',fontWeight:'bold'}:{}}>{item.name} {item.surname}</span>
                        :
                        <span style={item.id === this.state.group.id_owner?{color:'#44D4FF',fontWeight:'bold'}:{}}>{item.login}</span>
                      } </NavLink>}
                    />
                    
                </List.Item>
                )}
                />
                :
                <div>
                <List
                itemLayout="horizontal"
                dataSource={this.state.group_users.filter(user => user['name'].toString().includes(document.getElementById('username_search').value) || 
                user['login'].toString().includes(document.getElementById('username_search').value))}
                renderItem={item => (
                <List.Item extra = {
                    <Radio.Group disabled={this.isUserGroupReader()} onChange={(e) => this.ChangeUserState(item.id,e)} value={this.getGroupStatus(item.id)}>
                    <Radio disabled={this.isUserGroupReader()} value={"manager"}>Manager</Radio>
                    <Radio value={"member"}>Member</Radio>
                    <Radio value={"Deleted"}>Not in group</Radio>
                    </Radio.Group>}>
                    
                    {
                        this.state.staff || item.id === this.state.connected_user_id?
                        <List.Item.Meta
                    title={
                    <NavLink exact to={{pathname:`/display-user/`,aboutProps:item}}>           
                     {
                        item.name || item.surname?
                        <span style={item.id === this.state.group.id_owner?{color:'#44D4FF',fontWeight:'bold'}:{}}>{item.name} {item.surname}</span>
                        :
                        <span style={item.id === this.state.group.id_owner?{color:'#44D4FF',fontWeight:'bold'}:{}}>{item.login}</span>
                      } </NavLink>
                    }
                    />
                    :
                    <List.Item.Meta
                    title={
                    <span>           
                     {
                        item.name || item.surname?
                        <div>{item.name} {item.surname}</div>
                        :
                        <div>{item.login}</div>
                      } </span>
                    }
                    />
                    }

                </List.Item>
                )}
                />
                <br></br>
                <h3>Add an user using his email :</h3>  
                <Form>
                    <Form.Item>
                    {getFieldDecorator('email', {
                    rules: [
                        {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                        },
                    ],
                    })(<AutoComplete disabled={this.isUserGroupReader()} style={{ width: 400 }} placeholder="User email" onChange={value => this.setState({invited_email:value})}>
                        {
                            this.state.user_contact.map(user => <Option key={user.email} value={user.email}>{user.email}</Option>)
                        }
                    </AutoComplete>)}
                </Form.Item>
                <Form.Item>
                    <Button disabled={this.isUserGroupReader()} type="primary" onClick={this.sendEmailInvit} style={{marginRight: '10px'}} >
                        Send invit
                    </Button>
                </Form.Item>
                </Form>
                <div>{this.state.mail_message !== undefined ? <span>{this.state.mail_message}</span>:<span></span>}</div>
                </div>
            }

            </div>
            </div>
        );
    }
}

const WrappedGroupDetailForm = Form.create({ name: 'detail' })(GroupDetail);

//get data from page (router)
const mapStateToProps = (state) => {
  return {
      loading: state.loading,
      error : state.error,
      isAuthenticated: state.token !== null,
  }
}

//bind auth function
const mapDispatchToProps = dispatch => {
return {
  onAuth: (username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team) => dispatch(actions.authSignup(username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team))
}
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedGroupDetailForm);