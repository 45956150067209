import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store/store'
import DataLoader from "./DataLoader";
import PhyloTreeLoader from "./Dataset/PhyloTreeLoader";
const container = document.getElementById('app');
const app = (
    <Provider store = {store}>
        <DataLoader></DataLoader>

        <PhyloTreeLoader></PhyloTreeLoader>
        <App />
    </Provider>
)
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
