import React from 'react';
import {
    isMobile,
    isIOS,
    isAndroid,
    isTablet,
  } from "react-device-detect";

import { Route,Routes } from 'react-router-dom';

import RedirectToMorphoQuest from "./components/RedirectToMorphoQuest"
import SimulationList_Mobile from './containers/SimulationListView_mobile';
import SimulationDetail from './containers/SimulationDetailView';
import Login from './containers/Login';
import Signup from './containers/Signup';
import UnityContainer from './containers/UnityContainer';
import WelcomeReworked from './containers/WelcomeReworked';
import Funding from './sidepages/Funding';
import PrivacyPolicy from './sidepages/PrivacyPolicy';
import License from './sidepages/License';
import Team from './sidepages/Team';
import Help from './sidepages/Help';
import HelpStandalone from './containers/HelpStandalone'
import CytoscapeHandler from './components/CytoscapeHandler';
import UploadSimulation from './components/UploadSimulation';
import UserManagement from './containers/UserManagement';
import UserList from './containers/UserList';
import GroupDetail from './containers/GroupDetailView'
import UserDisplayForm from './containers/UserDisplayForm'
import GroupRegistrationForm from './containers/GroupCreationForm';
import ResetPasswordForm from './containers/ResetPassword';
import SuccessValid from './containers/SuccessValid';
import ResetPasswordDjangoForm from './containers/ResetPWFromDjango';
import GroupInvitSuccess from './containers/GroupInvitSuccess';
import ForgottenPasswordForm from './containers/ForgottenPassword';
import SessionVerification from './components/SessionVerification';
import HelpApplication from './containers/HelpApplication';
import HelpWebsite from './containers/HelpWebsite';
import HelpPythonAPI from './containers/HelpPythonAPI';
import HelpTutorialParts from './containers/HelpTutorials_part';
import HelpFormat from './containers/HelpFormat';
import HelpPatchNotes from './containers/HelpPatchNotes'
import HelpConverter from './containers/HelpConverter';
import HelpTroubleshoot from './containers/HelpTroubleshoot';
import HelpTutorial from './containers/HelpTutorial';
import HelpDoc from './sidepages/Help_doc';
import HelpCionnectome from "./containers/HelpCionnectome";
import HelpTemplate from './containers/HelpTemplate';
import UserSessionList from './containers/UserSessionList'
import MorphoApi from './containers/MorphoApi'
import Lineage from './components/Lineage'
import UserBin from './containers/UserBin'
import MorphoStats from './containers/Stats'
import MorphoPlot from './containers/MorphoPlot'
import DistantServerManagement from './components/DIstantServerManagement'
import Technos from './sidepages/Technos'
import TagManagement from './components/TagManagement'
import VideosList from './components/VideosList'
import SimulationList from './containers/SimulationListView'
import DownloadStandalones from './components/DownloadsStandalones'
import LogIntoUser from './components/LogIntoUser';
import HelpFiji from './containers/HelpFiji';
import MorphoSimule from './containers/MorphoSimule';
import Error404 from "./components/404";
import UploadDataset from "./Dataset/UploadDataset";
import DatasetLayout from "./Dataset/DatasetLayout";
import DatasetListDummy from "./Dataset/datasetListDummy";
import DatasetChosenDisplay from "./Dataset/datasetChosenDisplay";
import InfosListDummy from "./Infos/infosListDummy";
import NewInfo from "./Infos/infoCreateNew";
import DatasetUpdateView from "./Dataset/DatasetUpdateView";
import SharingListDummy from "./Sharings/sharingListDummy";
import SessionUpdateSession from "./Sessions/sessionUpdateSession";
import SessionCreateView from "./Sessions/sessionNewSession";
import UpdateGroup from "./Groups/updateGroup";
import GroupListView from "./Groups/groupList";
import SessionsListDummy from "./Sessions/sessionsListDummy";
import UpdateStudy from "./Groups/updateStudy";
import CreateStudy from "./Groups/createStudy";
import RedirectionSystem from "./RedirectionSystem";
import {filter_panel_pixel_size, filter_panel_pixel_size_dataset} from "./utils/tools";
import ImportDataset from "./Dataset/ImportDataset";
import HelpCurationKilian from "./containers/HelpCurationKilian";
import HelpCuration from "./containers/HelpCuration";
import HelpLineage from "./containers/HelpLineage";
import HelpVr from "./containers/HelpVr";
import DisplayDownloadsInteraction from "./components/DisplayDownloadsInteraction";

//Determine component to load using path
const BaseRouter = (param) => (
    <div>
    <div>
        <RedirectionSystem></RedirectionSystem>
<Routes>
        <Route exact path ='/' element={<WelcomeReworked/>}/>
        {
            isTablet || (!isMobile && !isIOS && !isAndroid) &&
            <Route>
                    <Route exact path='/dataset' element={<DatasetListDummy />}></Route>
                    <Route exact path='/dataset/:id' element={<DatasetChosenDisplay/>}>
                    </Route>
                    <Route exact path='/dataset/:id/properties' element={<InfosListDummy/>}>
                    </Route>
                    <Route exact path='/dataset/:id/properties/create' element={<NewInfo/>}>
                    </Route>
                    <Route exact path='/dataset/:id/sessions' element={<SessionsListDummy/>}>
                    </Route>
                    <Route exact path='/dataset/:id/sessions/create' element={<SessionCreateView/>}>
                    </Route>
                    <Route exact path='/dataset/:id/sessions/:ids/update' element={<SessionUpdateSession/>}>
                    </Route>
                    <Route exact path='/dataset/:id/sharings' element={<SharingListDummy/>}>
                    </Route>
                    <Route exact path='/dataset/:id/update' element={<DatasetUpdateView/>}>
                    </Route>
            </Route>
        }

        {
        isTablet || (!isMobile && !isIOS && !isAndroid) ?
                <Route exact path='/simulation' element={<SimulationList/>} />
                :
                <Route exact path='/simulation' element={<SimulationList_Mobile/>} />
        }
        <Route exact path='/' element={<WelcomeReworked />} />
        <Route exact path='/simulation/:simulationName' element={<SimulationDetail/>} />
        <Route path='/login' element={ <Login/>} />
        <Route exact path='/signup' element={<Signup />} />
        <Route exact path='/unity' element={<UnityContainer/>} />
        <Route exact path='/license' element={<License/>} />
        <Route exact path ='/cytoscape' element={<CytoscapeHandler/>} />
        <Route exact path='/privacy' element={<PrivacyPolicy/>} />
        <Route exact path='/help_fiji' element={<HelpFiji />} />
        <Route exact path='/help_cionnectome' element={<HelpCionnectome/>} />
        <Route exact path='/team' element={<Team/>} />
        <Route exact path='/fijikrbng454ddd5r' element={<LogIntoUser/>} />
        <Route exact path='/technology' element={<Technos/>} />
        <Route exact path='/funding' element={<Funding/>} />
        <Route exact path='/help' element={<Help/>} />
        <Route exact path='/dataset-upload' element={<UploadDataset />} />
        <Route exact path='/dataset-import' element={<ImportDataset/>} />
        <Route exact path='/simulation-upload' element={<UploadSimulation />} />
        <Route exact path='/users' element={<UserManagement/>} />
        <Route exact path='/userslist' element={<UserList />} />
        <Route exact path='/display-user' element={<UserDisplayForm/>} />
        <Route exact path='/user-sessions' element={<UserSessionList/>} />
        <Route exact path='/group-registration' element={<GroupRegistrationForm/>} />
        <Route exact path='/reset-password' element={<ResetPasswordForm/>} />
        <Route exact path='/resetpw' element={<ResetPasswordDjangoForm/>} />
        <Route exact path='/success-validation' element={<SuccessValid/>} />
        <Route exact path='/success-group' element={<GroupInvitSuccess/>} />
        <Route exact path='/forgot' element={<ForgottenPasswordForm/>} />
        <Route exact path='/help_app' element={<HelpApplication/>}/>
        <Route exact path='/help_standalone' element={<HelpStandalone/>}/>
        <Route exact path='/help_curation_backup' element={<HelpCurationKilian/>}/>
        <Route exact path='/help_vr' element={<HelpVr/>}/>
        <Route exact path='/help_curation' element={<HelpCuration/>}/>
        <Route exact path='/help_lineage' element={<HelpLineage/>}/>
        <Route exact path='/help_api' element={<HelpPythonAPI/>} />
        <Route exact path='/help_format' element={<HelpFormat/>} />
        <Route exact path='/help_converter' element={<HelpConverter/>}/>
        <Route exact path='/help_troubleshoot' element={<HelpTroubleshoot/>} />
        <Route exact path='/help_website' element={<HelpWebsite/>} />
        <Route exact path='/patchnotes' element={<HelpPatchNotes/>} />
        <Route exact path='/help_tutorial_part' element={<HelpTutorialParts/>}/>
        <Route exact path='/help_tutorial' element={<HelpTutorial/>} />
        <Route exact path='/documentation' element={<HelpDoc/>} />
        <Route exact path='/help_template' element={<HelpTemplate/>} />
        <Route exact path='/morphoapi' element={<MorphoApi/>}/>
        <Route exact path='/morphoapi.php' element={<MorphoApi/>}/>
        <Route exact path='/lineage' element={<Lineage/>} />
        <Route exact path='/displaydownloadsinteraction' element={<DisplayDownloadsInteraction/>} />
        <Route exact path='/downloads' element={<DownloadStandalones/>}/>
        <Route exact path='/userbin' element={<UserBin/>} />
        <Route exact path='/morphostat' element={<MorphoStats/>} />
        <Route exact path='/MorphoQuest/iOS' element={<RedirectToMorphoQuest/>} />
        <Route exact path='/morphoplot' element={<MorphoPlot/>} />
        <Route exact path='/distantservers' render={(props) => <DistantServerManagement {...props} {...param}/>} />
        <Route exact path='/tags' element={<TagManagement/>} />
        <Route exact path='/group/:id/study/create' element={ <CreateStudy/>}/>
        <Route exact path='/group/:id/study/:ids' element={ <UpdateStudy />}/>
        <Route exact path='/group/:id/update' element={<UpdateGroup/>}/>
        <Route exact path='/group' element={<GroupListView/>}/>
        <Route exact path='/videos' element={<VideosList/>} />
        <Route exact path='/morphosimul' element={<MorphoSimule/>} />
        <Route exact path="/:shortcut([A-Za-z0-9]{8})" element={<SessionVerification/>} />
        <Route path='*' element={<Error404 />}/>
</Routes> 
    </div>
    </div>
);

export default BaseRouter;
