import React from 'react';
import  { Navigate } from 'react-router-dom'
import {Typography, Input, Button } from 'antd';
import { Form } from '@ant-design/compatible';
import Icon from "@ant-design/icons";
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import * as actions from '../store/actions/auth';

const { Title } = Typography;

class NormalLoginForm extends React.Component {
  handleSubmit = e => {
    //
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
      localStorage.removeItem('is_staff');
      //Call onAuth function defined down on the page to modify global app stat (check if user is up)
       this.props.onAuth(values.username,values.password);
      }
            //when logged in, redirect page to dataset

    });
  };

  render() {

    //display error 
    let errorMessage = null;
    if (this.props.error !== "") {
        errorMessage = (
            <p>{this.props.error}</p>
        )
    }

  /*bind props to decorator pattern of the form*/
    const { getFieldDecorator } = this.props.form;
    return (
        <div>
 
      { 
      //display loading icon if currently loading
          this.props.isAuthenticated ?
          <div>
            <Navigate to='/dataset' />
          <br></br>
          </div>
          :
          <div style={{textAlign:'center'}}>
                    <Title style={{textAlign:'center'}}> Sign in </Title>
          <h1 style={{textAlign:'center',fontSize:'24px'}}>Use your MorphoNet Account</h1>
          <Form style={{marginTop:'7%'}} onSubmit={this.handleSubmit} className="login-form">

        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
            style={{width:'300px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)'}} />}
              placeholder="Username"
            />,
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              style={{width:'300px'}}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)'}} />}
              type="password"
              placeholder="Password"
            />,
          )}
          <br></br>
        <NavLink style={{marginRight: '10px'}} to='/forgot'> Forgotten password ?</NavLink>
        </Form.Item>
        
        <Form.Item>
            <Button type="primary" htmlType="submit" style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}}>
                Login
            </Button>
            Or 
            <NavLink style={{marginRight: '10px'}} onClick={() => {
              this.props.sendTracking(-1,"Web,Signup,view");
              }} to='/signup'> signup
            </NavLink> 
        </Form.Item>
      </Form>

      {this.props.error !== ""&&<span style={{marginTop:'1%',color:'red'}}>{this.props.error}</span>}
      </div>
  }
      </div>
    );
  }
}

//export login component, indicate its a form
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

//If bind into the export connect, allow you to load into the page the state
const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error : state.user.error_message,
        isAuthenticated: state.user.is_connected,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to Login function,it has to call onAuth to login the user in all the site)
const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username,password) => dispatch(actions.authLogin(username,password))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedNormalLoginForm);