// import react, react-markdown-editor-lite, and a markdown parser you like
import React from 'react'
function getIframeBodyTotalHeight() {
    var frm = document.getElementById("appframe");
    var frm_body = frm.contentWindow.document.body;

    var body_height = parseInt(frm_body.offsetHeight);
    var body_margin_top = parseInt(window.getComputedStyle(frm_body).getPropertyValue('margin-top').replace("px",""));
    var body_margin_bottom = parseInt(window.getComputedStyle(frm_body).getPropertyValue('margin-bottom').replace("px",""));
    var body_padding_top = parseInt(window.getComputedStyle(frm_body).getPropertyValue('padding-top').replace("px", ""));
    var body_padding_bottom = parseInt(window.getComputedStyle(frm_body).getPropertyValue('padding-bottom').replace("px", ""));

    var result = body_height + body_margin_top + body_margin_bottom + body_padding_top + body_padding_bottom;
    return result;
}
class RenderHTMLFromPage extends React.Component {
    constructor(props){
        super(props);
    }


    state = {
    }

    componentDidMount() {
    }

    render() {
        return (<embed id="appframe" style={{width:'100%',height:"100%",overflow:"hidden"}} type={"text/html"} onLoad={() => {setTimeout(()=>{this.style.height=getIframeBodyTotalHeight()+"px";window.scroll(0,0);},50)}} src={this.props.htmlLink}></embed>);
    }
}

export default RenderHTMLFromPage;